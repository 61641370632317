export const environment = {
    production: true,
    mockResponse: true,
    stripe_token: 'STRIPE_TOKEN',
    addressPressupAPI: 'https://pressup-api.azurewebsites.net/',
    addressPressupAPIDEM: 'https://pressup-api-dem.azurewebsites.net/api/',
    addressPressupBackofficeAPI: 'https://pressup-api-bo.democom.tech/api/',
    addressPressupBackoffice: 'https://pressup-api-bo.democom.tech/',
    addressPressupStrapi: 'https://pressupblog.democom.tech',
    api_v1: 'v1',
    api_v2: 'v2',
    domain: 'https://pressuptest.democom.tech/',
    photonApi: 'https://photon.komoot.io/api/',
    jwtStrapiPressup: '23d45a0ea9779645830e9c27852bc50df117990266411d7f581ee14c83f8b06d2e2f63ca0f5adfb8fb0138925d55cb3f37fe32df71cd1171d6da8e47d1a89d314d3d1bf4f4600e8bd1aaddc2231861d19e5ab7bc329e97f816bd650f83dfe822a849388c0f9bf4b8b5fb7924e70e709014d70cf1292a90156d1e7b183164913a',
    xApiKey: 'DBjkmVXr+LRQsE4z9nUnricKT11lfAenjYLlg+UsSvc='
};
